<template>
  <div class="footer border-top">
    <div class="safe-area padding-20">
      <div class="flex-row align-items-center justify-around">
        <div class="flex-row align-items-center">
          <img width="50" src="@/assets/images/foot-icon-01.png" />
          <div class="font-size-20 bold margin-left-5">品类齐全，轻松购物</div>
        </div>
        <div class="flex-row align-items-center">
          <img width="50" src="@/assets/images/foot-icon-02.png" />
          <div class="font-size-20 bold margin-left-5">仓库直发，极速配送</div>
        </div>
        <div class="flex-row align-items-center">
          <img width="50" src="@/assets/images/foot-icon-03.png" />
          <div class="font-size-20 bold margin-left-5">购药放心，精致服务</div>
        </div>
        <div class="flex-row align-items-center">
          <img width="50" src="@/assets/images/foot-icon-04.png" />
          <div class="font-size-20 bold margin-left-5">天天低价，畅选无忧</div>
        </div>
      </div>
      <div class="color-sub margin-top-20 text-align-center">银河药业－国家药监局认证的合法药品销售商，致力于打造优质、低价、便捷的网上药品批发和最值得信赖的健康服务平台</div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Footer',
  components: {},
  data() {
    return {

    }
  },
  methods:{
    
  },
  created(){
    
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  
  
</style>
