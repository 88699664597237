<template>
  <div class="top-tool-bar">
    <div class="top-tool-bar-con flex-row align-items-center justify-between">
      <div>欢迎来到银河药业网上商城！</div>
      <div class="flex-row align-items-center">
        <div class="link border-right margin-right-10 padding-right-10" @click="$root.jump({path:'/mall/home'})"><i class="el-icon-house"></i> 首页</div>
        
        <div class="link border-right margin-right-10 padding-right-10" @click="$root.jump({path:'/mall/articleList'})"><i class="el-icon-bell"></i> 通知公告</div>
        <div class="link border-right margin-right-10 padding-right-10" @click="$root.jump({path:'/user/collect'})"><i class="el-icon-star-off"></i> 我的收藏</div>
        <div class="link border-right margin-right-10 padding-right-10" @click="$root.jump({path:'/shoppingCart'})"><i class="el-icon-shopping-cart-full"></i> 购物车</div>
        <div class="link border-right margin-right-10 padding-right-10" @click="$root.jump({path:'/user/order'})"><i class="el-icon-tickets"></i> 我的订单</div>
        <div class="link border-right margin-right-10 padding-right-10" @click="$root.jump({path:'/user'})"><i class="el-icon-user"></i> 个人中心</div>
        <template v-if="$store.state.loginRes.success">
          <div class="border-right margin-right-10 padding-right-10"> 你好，<span class="color-green">{{$store.state.loginRes.user_name}}</span></div>
          <div class="link color-orange" @click="logout"> 退出</div>
        </template>
        <template v-else>
          <div class="border-right margin-right-10 padding-right-10"> 你好，<span class="color-green link" @click="$root.jump({path:'/login'})">请登录</span></div>
          <div class="link color-orange"> 免费注册</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopToolBar',
  components: {},
  data() {
    return {

    }
  },
  methods:{
    logout(){
      //弹窗提示
      this.$confirm('您要退出登录吗?', '退出登录', {
        customClass:"custom-msgbox",
        confirmButtonText: '继续退出',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        let params= {
          ...this.$store.state.basicParams,
        }
        this.$instance.post('/user/web_logout',this.$qs.stringify(params))
        .then(res=>{
          if(res.data.code==0){
            try{
              localStorage.removeItem("loginRes");
              localStorage.removeItem("loginStamp");
            }catch{
              //
            }
            this.$store.commit('stateInit')
            this.$root.jump({
              path:'/mall/home'
            })
          }
        })
        .catch(()=> {});        
      })
      .catch(() => {});
    },
   
  },
  created(){
    
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  .top-tool-bar{
    background:#f7f7f7;
    border-bottom:#ededed solid 1px;
    color: #909090;
    box-sizing: border-box;
    .top-tool-bar-con{
      height: 40px;
      max-width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      white-space: nowrap;
    }
  }
</style>
