<template>
  <div class="mall-header">
    <div class="safe-area flex-row align-items-center justify-between">
      <div class="flex-row align-items-center">
        <div class="link" @click="$root.jump({path:'/mall/home'})"><img height="60" src="@/assets/images/logo-index.png" /></div>
        <div v-if="name" class="border-left padding-left-20 font-size-24 padding-tb-5 margin-left-15">{{name}}</div>
      </div>
      
      <div class="search margin-lr-20">
        <i class="el-icon-search margin-lr-15"></i>
        <input class="input" v-model.trim="keyword" placeholder="搜索药品"  @keyup.enter="search" />
        <div class="btn hover-opacity" @click="search">搜索</div>
      </div>
      <div class="flex-row align-items-center">
        <div class="gwc hover-opacity" @click="$root.jump({path:'/shoppingCart'})">
          <i class="el-icon-shopping-cart-full"></i>
          我的购物车
        </div>
        <div class="flex-column align-items-center margin-left-30">
          <img height="65" src="@/assets/images/ewm.png" />
          <div>扫码下载app</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
export default {
  name: 'MallHeader',
  components: {},
  props:{
    name:String
  },
  data() {
    return {
      keyword:''
    }
  },
  methods:{
    search(){
      if(this.keyword){
        let q=crypto.encrypt({keyword:this.keyword})
        this.$root.jump({
          path:'/mall/prodList',
          query:{q}
        })
      }else{
        this.$message({message: '请输入搜索关键词',type:'warning',center:true,})
      }
    }
  },
  created(){
    
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  .mall-header{
    background: #fff;
    min-width: 1200px;
    .safe-area{height:120px}
    .search{
      flex: auto;
      display: flex;
      align-items: center;
      border: #fe5e12 solid 2px;
      border-radius: 40px;
      max-width: 500px;
      box-shadow: 0 0 10px rgba(253,118,57,0.3);
      .input{
        height: 40px;
        line-height: 40px;
        border:0;
        font-size: 14px;
        flex: auto;
      }
      .btn{
        height: 36px;
        line-height: 36px;
        background: linear-gradient(to right,#ff7c35,#ff3000);
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        border-radius: 40px;
        margin-right:2px;
        cursor: pointer;
      }
    }
    .gwc{
        height: 40px;
        line-height: 40px;
        background: linear-gradient(to right,#ff7c35,#ff3000);
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        border-radius: 40px;
        cursor: pointer;
        box-shadow: 0 0 10px rgba(253,118,57,0.3);
      }
  }
</style>
